import {SmartComponent} from "smart-component-js";

class CustomClickComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.init();
    }


    init() {
        const link = this.element.querySelector('.yxpType_LINK a');
        if (!link) return;
        link.addEventListener('click', (e) => {
            e.preventDefault();
            location.pathname = link.href.replace(location.origin, '');
        });
    }
}

export default CustomClickComponent;